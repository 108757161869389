<template>
	<div>
		<button class="bg-gray-900 hover:bg-gray-800 px-3 py-1 text-white w-auto" @click='toggle = !toggle'>
			 Nova Página
		</button>

		<div class="modal h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50" v-show='!toggle'>
			<!-- modal -->
			<div class="bg-white rounded shadow-lg w-1/2 p-3">
				<!-- modal header -->
				<div class="py-2 flex justify-between items-center">
					<h3 class="font-semibold text-lg">Inserir Página</h3>
					<span @click='toggle = !toggle'>
						<svg class="w-6 h-6 fill-current text-gray-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
						</svg>
					</span>
				</div>
				<!-- modal body -->
				<input type="text" v-model="name" class="w-full p-3 border" placeholder="Nome">
				
				<div class="flex justify-end items-center w-100 border-t p-3">
					<button class="bg-gray-900 hover:bg-gray-800 px-3 py-1 text-white text-sm uppercase" @click="savePage">Inserir</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	props: ['locale'],
	data() {
		return {
			name: '',
			getLocale: '',
			toggle: true
		}
	},
	mounted() {
	},
	created() {
		this.getLocale = this.locale;
	},
	methods: {
		savePage() {
			axios.post('/' + this.getLocale + '/page', {
				name: this.name
			}).then(res => {
				window.location.href = '/' + this.getLocale + '/page/' + res.data.id + '/edit';
			}).catch(err => {
				console.log(err)
			})
		}
	}
}
</script>