document.addEventListener( 'DOMContentLoaded', function () {

	// responsive menu
	let hamburger = document.querySelector("#hamburger");
	let close = document.getElementById('close-menu');

	var navLink = document.querySelector(".nav-link");

	let mobileMenu = document.getElementById('mobile-menu');

	if(hamburger){

		hamburger.addEventListener('click', function(){
			mobileMenu.classList.toggle('hidden');
		});

		close.addEventListener('click', function(){
			mobileMenu.classList.toggle('hidden');
		});

		var navLink = document.querySelectorAll(".r-nav-link");

		navLink.forEach(link => 
			link.addEventListener('click', function(){
				mobileMenu.classList.add('hidden');
			})
			);
	}

	// Fixed Header
		// var header = document.querySelector("#header");
		var header = document.querySelector("#header");

		if(header){

			var headerHeight = header.clientHeight;
			var logo = document.querySelector('.logo');

			let scrollpos = window.scrollY

			window.addEventListener('scroll', function() {
				scrollpos = window.scrollY;

				if(scrollpos >= headerHeight){
					header.classList.add('header-fixed');
					logo.classList.add('logo-primary');
					hamburger.classList.add('text-primary-900');
				} else {
					header.classList.remove('header-fixed');
					logo.classList.remove('logo-primary');
					hamburger.classList.remove('text-primary-900');
				}
			});
		}

	// Video
	if(document.body.contains(document.getElementById("bg-video"))){
		// Slow motion video
		var vid = document.getElementById('bg-video');
		vid.play();
		vid.playbackRate = 0.3;
	}

	var getSplide = document.querySelector(".splide");

	if(getSplide){
		new Splide( '.splide', {
			type   : 'loop',
			arrows: false,
			autoplay: true
		} ).mount();
	}
	

	// GSAP
	gsap.registerPlugin(ScrollTrigger);

	ScrollTrigger.defaults({
		markers: false,
		toggleActions: "restart pause reverse reset",
	});

	var stats = document.querySelector("#estatisticas");

		if(stats){
			// counters
			ScrollTrigger.create({
				start: 'top 500',
				end: 'bottom',
				trigger: '#estatisticas',
				onEnter: counters,
				onLeave: resetCounters,
				onEnterBack: counters,
				onLeaveBack: resetCounters,
				toggleClass: {
					className: 'circle-chart__circle', targets: '.motion-circle'
				}
			});
		}

	if(window.innerWidth > 769) {

		// var transportador = document.querySelector("#box-transportador");
		// if(transportador){
		// 	gsap.fromTo("#box-transportador", { opacity: 1 }, {
		// 		x: 0,
		// 		ease: "power2.out",
		// 		scrollTrigger: {
		// 			trigger: "#main-cta",
		// 			start: "top",
		// 			end: "bottom",
		// 			scrub: 1
		// 		}
		// 	});
		// }
		// var expedidor = document.querySelector("#box-expedidor");
		// if(expedidor){
		// 	gsap.fromTo("#box-expedidor", { opacity: 1 }, {
		// 		x: 0,
		// 		ease: "power2.out",
		// 		scrollTrigger: {
		// 			trigger: "#main-cta",
		// 			start: "top",
		// 			end: "bottom",
		// 			scrub: 1
		// 		}
		// 	});
		// }

		// como-funciona steps
		// const t1 = gsap.timeline({
		// 	scrollTrigger: {
		// 		trigger: "#como-funciona",
		// 		scrub: 1,
		// 		start: 'top ' + headerHeight,
		// 		end: "bottom",
		// 		pin: true
		// 	}
		// });

		var getStep = document.querySelectorAll(".step");

		getStep.forEach(element => 
			t1.from(element, {duration: .4, opacity: 0, delay: .2}),
		);

		// preços
		const t2 = gsap.timeline({
			scrollTrigger: {
				trigger: "#precos",
				scrub: .3,
				start: 'top ' + headerHeight,
				end: "bottom",
				pin: true
			}
		});

		var getPrice = document.querySelectorAll(".price-table");

		getPrice.forEach(element => 
			t2.from(element, {duration: .5, opacity: 0, delay: .2})
			);

		var getScreens = document.querySelectorAll(".screen");

		getScreens.forEach((panel, i) => {
			if(i != getScreens.length -1){
				ScrollTrigger.create({
					trigger: panel,
					start: "top +=150",
					pin: true, 
					pinSpacing: false
				});
			}
		});
		
	}

	if(window.innerWidth > 1024) {
		let downloads = document.querySelector("#downloads");

		if(downloads){
			// downloads
			ScrollTrigger.create({
				trigger: downloads,
				start: 'top +=200',
				end: 'bottom -300',
				pin: true
			});
		}
	}
});
