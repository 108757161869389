<template>
	<div>
		<draggable :list="getSections" v-bind="getOptions()" v-on:change="updateOrder" class="cursor-move">
			<div v-for="section in getSections" class="px-3 py-5 bg-white mb-2 shadow-lg border-2">
				<div class="flex flox-col items-center w-full">

					<div class="flex-none">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-5">
					  		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l4-4 4 4m0 6l-4 4-4-4" />
						</svg>
					</div>

					<div class="flex-auto">
						<a href="#" @click.prevent='openModal(section)' class="relative text-gray-600 hover:text-gray-800 font-bold">{{ section.name }}</a>
					</div>

					<div class="edit">
						<a href="#" @click.prevent="deleteSection(section.id)" class="relative">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-5 text-red-400 block">
	  							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
							</svg>
						</a>
					</div>		
				</div>
			</div>
		</draggable>

		<div class="modal min-h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50 z-50" v-show='!toggle'>
			<!-- modal -->
			<div class="bg-white rounded shadow-lg w-9/12 p-3">
				<!-- modal header -->
				<div class="py-2 flex justify-between items-center">
					<h3 class="font-bold text-sm uppercase">Inserir Secção</h3>
					<span @click='toggle = !toggle'>
						<svg class="w-6 h-6 fill-current text-gray-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
						</svg>
					</span>
				</div>
				<!-- modal body -->
				<input type="text" v-model="name" class="w-full p-3 border mb-3">

				<editor
				api-key="no-api-key"
				v-model="body"
				:init="{
				height: 500,
				menubar: false,
				branding: false,
				content_css: '/css/app.css',
				language: 'pt_PT',
				extended_valid_elements : '*[*]',
				forced_root_block : 'section',
				entity_encoding: 'raw',
				encoding: 'UTF-8',
				relative_urls : false,
				remove_script_host : true,
              	document_base_url : getUrl,
				plugins: [
				'advlist autolink lists link image charmap print preview anchor',
				'searchreplace visualblocks code fullscreen',
				'insertdatetime media table paste code help wordcount link template'
				],
				toolbar:
				['undo redo | formatselect | removeformat bold italic forecolor | \
				alignleft aligncenter alignright | \
				bullist numlist | image link | code template'],
				link_list: function(success) { // called on link dialog open
				    var links = getJsonPages; // get link_list data
				    success(links); // pass link_list data to TinyMCE
				},
				link_title: false,
				target_list: false,
				link_class_list: [
				 	{title: 'Normal', value: ''},
				    {title: 'CTA button', value: 'btn-primary'},
				    {title: 'General button', value: 'bg-primary-900 text-white py-4 px-8 uppercase font-bold rounded block sm:inline my-10 hover:bg-primary-800'},
				    {title: 'button+icon', value: 'font-bold block flex items-center text-gray-500 text-sm uppercase mt-5'},
				  ],
				templates: [
				    {title: 'Testemunhos', description: '', content: '<!--[shortcode_testemunhos]-->'},
					{title: 'Artigos em destaque', description: '', content: '<!--[shortcode_artigos_destaque]-->'},
					{title: 'Artigos', description: '', content: '<!--[shortcode_posts]-->'},
					{title: 'Características', description: '', content: '<!--[shortcode_features]-->'},
					{title: 'Preços', description: '', content: '<!--[shortcode_prices]-->'},
					{title: 'Faqs', description: '', content: '<!--[shortcode_faqs]-->'},
				    {title: 'CTA', description: '', content: '<!--[shortcode_cta]-->'},
				    {title: 'Estatísticas', description: '', content: '<!--[shortcode_stats]-->'},
				    {title: 'Formulário de registo', description: '', content: '<!--[shortcode_register_form]-->'},
				    {title: 'Equipa', description: '', content: '<!--[shortcode_equipa]-->'},
				    {title: 'Passos', description: '', content: '<!--[shortcode_steps]-->'},
				    {title: 'Logos', description: '', content: '<!--[shortcode_logos]-->'},
				    {title: 'Newsletter', description: '', content: '<!--[shortcode_newsletter]-->'},
				    {title: 'Logo 40Pés Branco', description: '', content: '<!--[shortcode_logo]-->'},
				    {title: 'Logo 40pés Cor', description: '', content: '<!--[shortcode_logo_primary]-->'}
				  ]
				}"
			/>

<!-- 			<img :src="'/storage/sections/images/' + bg_image" v-if="bg_image != ''" alt="image">

			<input type="file" @change="getFile" class="w-full p-2 border mt-5"> -->

			<div class="flex justify-end items-center w-100 border-t p-3">
				<button class="bg-gray-900 hover:bg-gray-800 px-3 py-1 text-white text-sm uppercase" @click.prevent="updateSection(id)">Guardar</button>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import editor from '@tinymce/tinymce-vue'
import draggable from 'vuedraggable'

export default {
	props: ['locale', 'sections', 'url'],
	components: {
		editor, draggable
	},
	data() {
		return {
			id: '',
			name: '',
			body: '',
			bg_image: '',
			getLocale: '',
			getSections: [],
			toggle: true,
			getJsonPages: [],
			getUrl: ''
		}
	},
	mounted() {
	},
	created() {
		this.getLocale = this.locale;
		this.getSections = this.sections;
		this.getUrl = this.url;
		this.fetchLinkList();
	},
	methods: {
		fetchLinkList() {
			axios.get('/' + this.getLocale + '/page')
			.then(response => {
				response.data.forEach(page => {
					this.getJsonPages.push({title: page.name, value: this.getLocale + '/page/' + page.slug});
				})
			}).catch(error => {
				console.log(error)
			});
		},
		getFile(event) {
            this.bg_image = event.target.files[0];
            console.log(event.target.files[0]);
        },
		updateOrder() {
			this.getSections.map((section, index) => {
				section.order = index + 1;
			});

			var sections = this.getSections;

			axios.post('/' + this.getLocale + '/sections', {
				'sections': sections,
				_method: "patch"
			}).then(response => {
				this.getSections();
			}).catch(error => {
				console.log(error)
			});
		},
		show() {
			this.isActive = !this.isActive;
		},
		openModal(section){
			this.toggle = !this.toggle;

			this.id = section.id;
			this.name = section.name;
			this.body = section.body;
			this.bg_image = section.bg_image;
		},
		updateSection(id) {
			let formData = new FormData();
            formData.append('bg_image', this.bg_image);

			axios.post('/' + this.getLocale + '/section/' + id, {
				formData,
				name: this.name,
				body: this.body,
				bg_image: this.bg_image,
				headers: {
                    'Content-Type': 'multipart/form-data'
                },
                _method: "patch"
			}).then(res => {
				window.location.href = window.location.href
			}).catch(err => {
				console.log(err)
			})
		},
		deleteSection(section)
		{
			console.log(section);
			if(confirm('Tem certeza? Esta ação é irreversível.')){
				axios.delete('/' + this.getLocale + '/delete-section/' + section, {
					section: section, 
					_method: "delete"
				})
				.then(response => {
					alert('Secção removida'),
					window.location.href = window.location.href
				}).catch(error => {
					console.log(error)
				});
			}
		},
		getOptions() {
			// draggable
			return {
				animation: 100,
				forceFallback: true
			}
		}
	}
}
</script>