<template>
  <div class="fixed top-0 left-0 w-screen h-screen z-50 bg-black bg-opacity-80" v-if="show == true">

    <div class="relative flex flex-col sm:flex-row items-center bg-white shadow rounded-md p-10 w-1/3 mx-auto top-20">
      <div class="flex flex-row items-center border-b sm:border-b-0 w-full sm:w-auto pb-4 sm:pb-0">
        <div v-html="icon"></div>
        <div class="text-sm font-medium ml-3" v-html="message"></div>
      </div>
      <div class="absolute sm:relative sm:top-auto sm:right-auto ml-auto right-4 top-4 text-gray-400 hover:text-gray-800 cursor-pointer" @click="toggleShow">
        <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
      </div>
    </div>
   
  </div>
</template>

<script>
export default {
  props: ['success', 'error'],
  data() {
    return {
      message: '',
      show: false,
      icon: '',
    };
  },
  mounted() {
    if(this.success != '') {
      this.message = this.success
      this.icon = '<div class="text-green-500"><svg class="w-6 sm:w-5 h-6 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg></div>'
      this.show = true
    } 
    if(this.error != ''){
      this.message = this.error
      this.icon = '<div class="text-red-500"><svg class="w-6 sm:w-5 h-6 sm:h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></div>'
      this.show = true
    }
  },
  methods: {
    toggleShow() {
      this.show = false
    }
  }
};
</script>
