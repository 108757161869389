<template>
	<div>
		<button class="bg-gray-900 hover:bg-gray-800 px-3 py-1 text-white w-auto" @click.prevent='show = !show'>
			Nova Categoria
		</button>

		<div class="modal h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50 z-50" v-show='!show'>
			<!-- modal -->
			<div class="bg-white rounded shadow-lg w-9/12 p-3">
				<!-- modal header -->
				<div class="py-2 flex justify-between items-center">
					<h3 class="font-bold text-sm uppercase">Nova Categoria</h3>
					<span @click='show = !show'>
						<svg class="w-6 h-6 fill-current text-gray-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
						</svg>
					</span>
				</div>
				<!-- modal body -->
				<input type="text" v-model="name" class="w-full p-2 border mb-5" placeholder="Nome">
				
				<div class="flex justify-end items-center w-100 border-t p-3">
					<button class="bg-gray-900 hover:bg-gray-800 px-3 py-1 text-white text-sm uppercase" @click.prevent="createCategoria">Inserir</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	props: ['locale'],
	data() {
		return {
			name: '',
			getLocale: '',
			show: true,
		}
	},
	mounted() {
	},
	created() {
		this.getLocale = this.locale;
	},
	methods: {
		createCategoria() {
            axios.post('/' + this.getLocale + '/category',  {
            	name: this.name
            })
            .then(response => {
                window.location.href = window.location.href
            })
            .catch(error => {
                console.log(error)
            });
		}
	}
}
</script>