var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{staticClass:"bg-gray-900 hover:bg-gray-800 px-3 py-1 text-white w-auto mb-3",on:{"click":function($event){$event.preventDefault();_vm.show = !_vm.show}}},[_vm._v("\n\t\tNova Secção\n\t")]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.show),expression:"!show"}],staticClass:"modal h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50 z-50"},[_c('div',{staticClass:"bg-white rounded shadow-lg w-9/12 p-3"},[_c('div',{staticClass:"py-2 flex justify-between items-center"},[_c('h3',{staticClass:"font-bold text-sm uppercase"},[_vm._v("Inserir Secção")]),_vm._v(" "),_c('span',{on:{"click":function($event){_vm.show = !_vm.show}}},[_c('svg',{staticClass:"w-6 h-6 fill-current text-gray-900",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M6 18L18 6M6 6l12 12"}})])])]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"w-full p-2 border mb-5",attrs:{"type":"text","placeholder":"Nome"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_vm._v(" "),_c('editor',{attrs:{"api-key":"no-api-key","init":{
			height: 500,
			menubar: false,
			branding: false,
			content_css: '/css/app.css',
			language: 'pt_PT',
			extended_valid_elements : '*[*]',
			forced_root_block : 'section',
			encoding: 'UTF-8',
			plugins: [
			'advlist autolink lists link image charmap print preview anchor',
			'searchreplace visualblocks code fullscreen',
			'insertdatetime media table paste code help wordcount link template'
			],
			toolbar:
			['undo redo | formatselect | removeformat bold italic forecolor | \
			alignleft aligncenter alignright | \
			bullist numlist | image link | code template'],
			templates: [
				{title: 'Testemunhos', description: '', content: '<!--[shortcode_testemunhos]-->'},
				{title: 'Artigos em destaque', description: '', content: '<!--[shortcode_artigos_destaque]-->'},
				{title: 'Artigos', description: '', content: '<!--[shortcode_posts]-->'},
				{title: 'Características', description: '', content: '<!--[shortcode_features]-->'},
				{title: 'Preços', description: '', content: '<!--[shortcode_prices]-->'},
				{title: 'Faqs', description: '', content: '<!--[shortcode_faqs]-->'},
			    {title: 'CTA', description: '', content: '<!--[shortcode_cta]-->'},
			    {title: 'Estatísticas', description: '', content: '<!--[shortcode_stats]-->'},
			    {title: 'Formulário de registo', description: '', content: '<!--[shortcode_register_form]-->'},
			    {title: 'Equipa', description: '', content: '<!--[shortcode_equipa]-->'},
			    {title: 'Passos', description: '', content: '<!--[shortcode_steps]-->'},
			    {title: 'Newsletter', description: '', content: '<!--[shortcode_newsletter]-->'},
			    {title: 'Logo 40Pés Branco', description: '', content: '<!--[shortcode_logo]-->'},
			    {title: 'Logo 40pés Cor', description: '', content: '<!--[shortcode_logo_primary]-->'}
			  ]
			}},model:{value:(_vm.body),callback:function ($$v) {_vm.body=$$v},expression:"body"}}),_vm._v(" "),_c('div',{staticClass:"flex justify-end items-center w-100 border-t p-3"},[_c('button',{staticClass:"bg-gray-900 hover:bg-gray-800 px-3 py-1 text-white text-sm uppercase",on:{"click":function($event){$event.preventDefault();return _vm.createSection($event)}}},[_vm._v("Inserir")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }