<template>
	<div>
		<editor
				api-key="no-api-key"
				v-model="getBody"
				v-on:input="getValue"
				name="body"
				:init="{
				height: 500,
				menubar: false,
				branding: false,
				content_css: '/css/app.css',
				language: 'pt_PT',
				extended_valid_elements : '*[*]',
				forced_root_block : 'p',
				paste_as_text: true,
				encoding: 'UTF-8',
				file_picker_types: 'image',
				images_upload_url: '{{getPostUrl}}',
				images_upload_base_path: '/',
				automatic_uploads: false,
				plugins: [
				'advlist autolink lists link image charmap print preview anchor',
				'searchreplace visualblocks code fullscreen',
				'insertdatetime table paste code help wordcount'
				],
				toolbar: 'undo redo | formatselect | ' +
				'bold italic underline forecolor | alignleft aligncenter ' +
				'alignright alignjustify | bullist numlist | ' +
				'removeformat | image code | fullscreen'
				}"
				class="w-full focus:outline-none focus:text-gray-600 p-2"
			/>
	</div>
	
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
	props: ['posturl', 'lbody'],
	components: {
     	'editor': Editor
   	},
	data() {
		return {
			getPostUrl: '',
			getBody: ''
		}
	},
	mounted() {
		this.getBody = this.lbody
		this.getPostUrl = this.posturl

		if(this.body != '') {
			this.body = this.getBody
		}

	},
	created() {
	},
	methods: {
		getValue(){
            this.$emit('body', this.getBody);
        }
	}
}
	
</script>