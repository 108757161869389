<template>
	<div>

		<h4 class="text-xs uppercase font-bold mb-3">{{ getRelated}}</h4>
		<div class="lg:grid lg:grid-cols-2 gap-8">
			<div v-for="(post, index) in getPosts" v-if="post.id != getPostId">

				<div v-for="translations in post.translations" v-if="translations.locale === getLocale" class="col-start-1 relative block h-screen/2 mb-3">
		
					<a :href="'/' + translations.locale + '/post/' + post.slug">
						<img :src="'/storage/posts/images/' + post.cover_image" class="object-cover w-full h-screen/2 bg-center" alt="post image" loading="lazy" />
					</a>
						
					<div class="absolute bottom-0 left-0 bg-gray-900 p-5 w-full bg-opacity-80">
						<a :href="'/' + translations.locale + '/post/' + post.slug" class="text-white uppercase text-lg font-bold hover:underline">{{ translations.title }}</a>
							
						<p class="mb-3 text-sm font-normal text-white">
							{{ translations.body_excerpt }} …
						</p>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>	

<style scoped>
</style>

<script>
	export default {
		props: ['posts', 'locale', 'post_id', 'related'],
		data() {
			return {
				getLocale: '',
				getPosts: [],
				getPostId: '',
				getRelated: ''
			}
		},
		mounted(){
			this.getRelated = this.related;
		},
		created() {
			this.getLocale = this.locale;
			this.getPosts = this.posts;
			this.getPostId = this.post_id;
			
		},
		computed: {
		},
		methods: {

		}
	}
</script>