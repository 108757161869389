<template>
	<div>
		<button class="bg-gray-900 hover:bg-gray-800 px-3 py-1 text-white w-auto mb-3" @click.prevent='show = !show'>
			Nova Secção
		</button>

		<div class="modal h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50 z-50" v-show='!show'>
			<!-- modal -->
			<div class="bg-white rounded shadow-lg w-9/12 p-3">
				<!-- modal header -->
				<div class="py-2 flex justify-between items-center">
					<h3 class="font-bold text-sm uppercase">Inserir Secção</h3>
					<span @click='show = !show'>
						<svg class="w-6 h-6 fill-current text-gray-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
						</svg>
					</span>
				</div>
				<!-- modal body -->
				<input type="text" v-model="name" class="w-full p-2 border mb-5" placeholder="Nome">

				<editor
				api-key="no-api-key"
				v-model="body"
				:init="{
				height: 500,
				menubar: false,
				branding: false,
				content_css: '/css/app.css',
				language: 'pt_PT',
				extended_valid_elements : '*[*]',
				forced_root_block : 'section',
				encoding: 'UTF-8',
				plugins: [
				'advlist autolink lists link image charmap print preview anchor',
				'searchreplace visualblocks code fullscreen',
				'insertdatetime media table paste code help wordcount link template'
				],
				toolbar:
				['undo redo | formatselect | removeformat bold italic forecolor | \
				alignleft aligncenter alignright | \
				bullist numlist | image link | code template'],
				templates: [
					{title: 'Testemunhos', description: '', content: '<!--[shortcode_testemunhos]-->'},
					{title: 'Artigos em destaque', description: '', content: '<!--[shortcode_artigos_destaque]-->'},
					{title: 'Artigos', description: '', content: '<!--[shortcode_posts]-->'},
					{title: 'Características', description: '', content: '<!--[shortcode_features]-->'},
					{title: 'Preços', description: '', content: '<!--[shortcode_prices]-->'},
					{title: 'Faqs', description: '', content: '<!--[shortcode_faqs]-->'},
				    {title: 'CTA', description: '', content: '<!--[shortcode_cta]-->'},
				    {title: 'Estatísticas', description: '', content: '<!--[shortcode_stats]-->'},
				    {title: 'Formulário de registo', description: '', content: '<!--[shortcode_register_form]-->'},
				    {title: 'Equipa', description: '', content: '<!--[shortcode_equipa]-->'},
				    {title: 'Passos', description: '', content: '<!--[shortcode_steps]-->'},
				    {title: 'Newsletter', description: '', content: '<!--[shortcode_newsletter]-->'},
				    {title: 'Logo 40Pés Branco', description: '', content: '<!--[shortcode_logo]-->'},
				    {title: 'Logo 40pés Cor', description: '', content: '<!--[shortcode_logo_primary]-->'}
				  ]
				}"
			/>

			<!-- 	<input type="file" @change="getFile" ref="file" class="w-full p-2 border mt-5"> -->

				
				<div class="flex justify-end items-center w-100 border-t p-3">
					<button class="bg-gray-900 hover:bg-gray-800 px-3 py-1 text-white text-sm uppercase" @click.prevent="createSection">Inserir</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
	props: ['locale', 'page_translation_id', 'testimonials'],
	components: {
     	'editor': Editor
   	},
	data() {
		return {
			name: '',
			body: '',
			getLocale: '',
			getTrans: '',
			show: true,
			getTestimonials: '',
			bg_image: ''
		}
	},
	mounted() {
		this.getTestimonials = this.testimonials
	},
	created() {
		this.getLocale = this.locale;
		this.getTrans = this.page_translation_id;
	},
	methods: {
		getFile(event) {
            this.bg_image = event.target.files[0];
        },
		createSection() {
            let formData = new FormData();
            formData.append('bg_image', this.bg_image);

            axios.post('/' + this.getLocale + '/section-store',  {
            	formData,
            	name: this.name,
				body: this.body,
            	page_translation_id: this.getTrans,
            	headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    window.location.href = window.location.href
                })
                .catch(error => {
                    console.log(error)
            });
		}
	}
}
</script>