<template>
	<div>
		<div class="grid grid-cols-2 w-full relative">
			<nav class="mb-10 flex-1" id="categories">
				<a :href="'/' + getLocale + '/page/imprensa'" class="mr-3 text-xs uppercase font-bold text-primary-900">{{ getImprensa }}</a>| 
				<span v-for="category in getCategories">
					<a v-for="cat in category.translations" v-if="cat.locale == getLocale" :href="'/' + getLocale + '/category/' + category.id + '?name=' + cat.slug" class="mr-3 text-xs uppercase">{{ cat.name }}</a>
				</span>
				<a :href="'/' + getLocale + '/page/artigos'" v-if="showVerTudo" class="mr-3 text-xs uppercase">{{ getVerTudo }}</a>
			</nav>

			<div class="text-right absolute right-0">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-5 h-5 absolute right-0 top-1 text-gray-400">
				  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
				</svg>

    			<input type="text" v-model="search" :placeholder="getProcurar" class="focus:border-primary-900 p-1 text-sm border-b appearance-none focus:outline-none" />
        	</div>
        </div>

		<div class="lg:grid lg:grid-cols-3 xl:grid-cols-4 gap-8" id="post-list">
			<div v-for="(post, index) in filteredPosts" v-if="index <= showPosts">
				<div v-for="translations in post.translations" v-if="translations.locale === getLocale" class="col-start-1 relative block h-screen/2 mb-3">
		
					<a :href="'/' + translations.locale + '/post/' + post.slug">
						<img :src="'/storage/posts/images/' + post.cover_image" class="object-cover w-full h-screen/2 bg-center" alt="post image" loading="lazy" />
					</a>
						
					<div class="absolute bottom-0 left-0 bg-gray-900 p-5 w-full bg-opacity-80">
						<a :href="'/' + translations.locale + '/post/' + post.slug" class="text-white uppercase text-lg font-bold hover:underline">{{ translations.title }}</a>
							
						<p class="mb-3 text-sm font-normal text-white">
							{{ translations.body_excerpt }} …
						</p>
					</div>
				</div>
			</div>
		</div>

		<div v-if="showPosts < filteredPosts.length -1 || filteredPosts.length -1 > showPosts" class="text-center">
			<button class="text-center border border-primary-900 py-2 px-5 uppercase font-bold text-sm my-10 w-auto text-primary-900 rounded hover:bg-primary-900 hover:text-white hover:border-0" @click.prevent="showPosts += 4">{{ getVerMais }}</button>
		</div>
	</div>
</template>	

<style scoped>

#post-list > div:nth-child(3n+1) {
	grid-column: span 2;
}
#post-list > div:nth-child(4n+1) {
	grid-column: span 2;
}


</style>

<script>
	export default {
		props: ['posts', 'locale', 'categories', 'imprensa', 'vertudo', 'vermais', 'procurar'],
		data() {
			return {
				getLocale: '',
				getPosts: [],
				getCategories: [],
				showPosts: 4,
				showVerTudo: false,
				getImprensa: '',
				getVerTudo: '',
				getVerMais: '',
				search: '',
				getProcurar: '',
				isOpen: false
			}
		},
		mounted(){

			let urlParams = new URLSearchParams(window.location.search);
			let catName = urlParams.get('name');
			let catId = urlParams.get('catId');

			if(catName){
				this.showVerTudo = true
			} else {
				this.showVerTudo = false
			}
		},
		created() {
			this.getImprensa = this.imprensa;
			this.getVerTudo = this.vertudo;
			this.getVerMais = this.vermais;
			this.getProcurar = this.procurar;
			this.getLocale = this.locale;
			this.getPosts = this.posts;
			this.getCategories = this.categories;
		},
		computed: {
		    filteredPosts() {
			    return this.getPosts.filter(post => {
			    	if(post.title != null){
			    		return post.title.toLowerCase().includes(this.search.toLowerCase())
			    	}
			    })
		    }
		},
		methods: {
		}
	}
</script>