require('./bootstrap');
require('./custom-scripts');

window.Vue = require('vue');

Vue.component('create-page-modal', require('./components/CreatePageModal.vue').default);
Vue.component('create-testimonial-modal', require('./components/CreateTestimonialModal.vue').default);
Vue.component('sections', require('./components/Sections.vue').default);
Vue.component('create-section-modal', require('./components/CreateSectionModal.vue').default);
Vue.component('create-category-modal', require('./components/CreateCategoryModal.vue').default);
Vue.component('posts', require('./components/PostsComponent.vue').default);
Vue.component('related-posts', require('./components/RelatedPostsComponent.vue').default);
Vue.component('tiny-editor', require('./components/TinyEditor.vue').default);
Vue.component('flash-message', require('./components/FlashMessage.vue').default);

Vue.config.productionTip = false

const app = new Vue({
	el: '#app'
});

// var getClass = document.querySelector(".editor");
	
// 	if(getClass){
// 		var editor_config = {
// 			selector: '.editor',
// 			language: 'pt_PT',
// 			menubar: false,
// 			height: 600,
// 			cleanup: false,
// 			branding: false,
// 			draggable_modal: true,
// 			content_css: "/css/app.css",
// 			extended_valid_elements : "*[*]",
// 			forced_root_block : 'section',
// 			encoding: 'UTF-8',
// 			apply_source_formatting : true,
// 			end_container_on_empty_block: true,
// 			allow_html_in_named_anchor: true,
// 			automatic_uploads: true,
// 			relative_urls: false,
// 			path_absolute : "/",
// 			document_base_url : "/",
// 			file_picker_types: 'image',
// 			images_upload_url: 'pt/uploads',
// 			plugins: [
// 			'advlist autolink lists link image charmap print preview anchor',
// 			'searchreplace visualblocks code fullscreen',
// 			'insertdatetime table paste code help wordcount'
// 			],
// 			toolbar: 'undo redo | formatselect | ' +
// 			'bold italic underline forecolor | alignleft aligncenter ' +
// 			'alignright alignjustify | bullist numlist | ' +
// 			'removeformat | image code | fullscreen',
// 			// file_picker_callback: function(cb, value, meta) {
//    //              var input = document.createElement('input');
//    //              input.setAttribute('type', 'file');
//    //              input.setAttribute('accept', 'image/*');
//    //              input.onchange = function() {
//    //                  var file = this.files[0];

//    //                  var reader = new FileReader();
//    //                  reader.readAsDataURL(file);
//    //                  reader.onload = function () {
//    //                      var id = 'blobid' + (new Date()).getTime();
//    //                      var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
//    //                      var base64 = reader.result.split(',')[1];
//    //                      var blobInfo = blobCache.create(id, file, base64);
//    //                      blobCache.add(blobInfo);
//    //                      cb(blobInfo.blobUri(), { title: file.name });
//    //                  };
//    //              };
//    //              input.click();
//    //          }
// 		};

// 		tinymce.init(editor_config);
// 	}