var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('editor',{staticClass:"w-full focus:outline-none focus:text-gray-600 p-2",attrs:{"api-key":"no-api-key","name":"body","init":{
			height: 500,
			menubar: false,
			branding: false,
			content_css: '/css/app.css',
			language: 'pt_PT',
			extended_valid_elements : '*[*]',
			forced_root_block : 'p',
			paste_as_text: true,
			encoding: 'UTF-8',
			file_picker_types: 'image',
			images_upload_url: '{{getPostUrl}}',
			images_upload_base_path: '/',
			automatic_uploads: false,
			plugins: [
			'advlist autolink lists link image charmap print preview anchor',
			'searchreplace visualblocks code fullscreen',
			'insertdatetime table paste code help wordcount'
			],
			toolbar: 'undo redo | formatselect | ' +
			'bold italic underline forecolor | alignleft aligncenter ' +
			'alignright alignjustify | bullist numlist | ' +
			'removeformat | image code | fullscreen'
			}},on:{"input":_vm.getValue},model:{value:(_vm.getBody),callback:function ($$v) {_vm.getBody=$$v},expression:"getBody"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }